import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import RightArrow from "../../../images/right-arrow.svg";
import { convertBlogSlug } from "../../../utils/blog";

interface LatestBlogsProps {
  latestBlogPost: {
    title: string;
    slug: string;
    bannerImage: {
      gatsbyImageData: any;
    };
  }[];
}

const LatestBlogs: React.FC<LatestBlogsProps> = props => {
  const { latestBlogPost } = props;
  return (
    <div className="my-12 w-full">
      <h3 className="font-semibold text-4xl text-center mb-12">Latest Blogs</h3>
      <div className="flex flex-col lg:flex-row gap-6 w-full mx-auto items-center">
        {latestBlogPost?.map((post, index) => {
          return (
            <div
              key={index}
              className="flex flex-col gap-3 max-w-2xl lg:max-w-md mb-8 lg:mb-0"
            >
              <GatsbyImage
                class="rounded-lg max-h-[252px]"
                alt={post.title}
                image={post?.bannerImage?.gatsbyImageData}
              />
              <h3 className="text-lg font-semibold lg:max-w-sm lg:min-h-[72px]">
                {post?.title}
              </h3>

              <a
                className="text-indigo-1 font-semibold text-base flex hover:text-indigo-2 items-center mt-5"
                href={convertBlogSlug(post?.slug, "en-US")}
              >
                Read More
                <RightArrow className="ml-2" />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatestBlogs;
