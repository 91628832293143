import React from "react";
import Icon, { IconType } from "../../Icon";

interface SocialLinksProps {
  socialLinks: {
    link: string;
    icon: IconType;
  }[];
}

const SocialLinks: React.FC<SocialLinksProps> = props => {
  const { socialLinks } = props;
  return (
    <div className="flex justify-center items-center gap-5 sm:gap-9 my-12 flex-wrap w-52 sm:w-full">
      {socialLinks?.map((link, index) => {
        return (
          <a
            key={index}
            target="_blank"
            rel="nofollow"
            href={link?.link}
            className="bg-white rounded-full p-3 shadow"
          >
            <Icon className="w-[16px] h-[16px]" iconType={link?.icon} />
          </a>
        );
      })}
    </div>
  );
};

export default SocialLinks;
