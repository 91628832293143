import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/Seo";
import LogoWordmark from "../components/NavigationBar/assets/logo-wordmark.svg";
import Section from "../containers/Section";
import SocialLinks from "../components/LinkInBio/SocialLinks";
import ResourcesLinks from "../components/LinkInBio/ResourcesLinks";
import LatestBlogs from "../components/LinkInBio/LatestBlogs";
import { IconType } from "../components/Icon";

interface TikTokProps {
  data: any;
}

const TikTok: React.FC<TikTokProps> = ({ data }) => {
  const latestBlogPost = data?.allContentfulPublication?.nodes;
  const resourcesLinks =
    data?.allContentfulLinkInBio?.nodes[0]?.tikTokResourcesLinks;

  const socialLinks = [
    {
      link: "https://www.instagram.com/perpetua.io/",
      icon: IconType.instagram
    },
    {
      link: "https://www.tiktok.com/@perpetua.io",
      icon: IconType.tiktok
    },
    {
      link: "https://www.youtube.com/channel/UCVPWE5c8kRN6QOcp8ntIbTw",
      icon: IconType.youtube
    },
    {
      link: "https://www.linkedin.com/company/perpetua-labs/",
      icon: IconType.linkedin
    },
    {
      link: "https://www.facebook.com/perpetualabs/",
      icon: IconType.facebook
    },
    {
      link: "https://twitter.com/PerpetuaLabs",
      icon: IconType.twitter
    }
  ];

  return (
    <main className="bg-gradient-to-b from-white via-white to-indigo-3-light relative min-h-screen overflow-hidden">
      <Section className="flex flex-col items-center py-16">
        <SEO title={""} description={""} robots="noindex nofollow" />
        <a href="/">
          <LogoWordmark />
        </a>

        <SocialLinks socialLinks={socialLinks} />
        <ResourcesLinks resourcesLinks={resourcesLinks} />
        <LatestBlogs latestBlogPost={latestBlogPost} />
      </Section>
    </main>
  );
};

export default TikTok;

export const data = graphql`
  query {
    allContentfulPublication(
      limit: 3
      sort: { order: DESC, fields: publishDate }
      filter: { node_locale: { eq: "en-US" }, title: {ne: null} }
    ) {
      nodes {
        slug
        title
        bannerImage {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    allContentfulLinkInBio(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        tikTokResourcesLinks {
          slug
          text
        }
      }
    }
  }
`;
