import React from "react";

interface ResourcesProps {
  resourcesLinks: {
    text: string;
    slug: string;
  }[];
}

const ResourcesLinks: React.FC<ResourcesProps> = props => {
  const { resourcesLinks } = props;
  return (
    <div className="sm:max-w-xl lg:max-w-2xl w-full flex flex-col gap-6 text-center mt-6">
      {resourcesLinks?.map((link, index) => {
        return (
          <p
            key={index}
            className="border-2 rounded-lg p-5 border-indigo-1 w-full"
          >
            <a
              className="font-semibold text-indigo-1 hover:text-indigo-2"
              href={link?.slug}
            >
              {link?.text}
            </a>
          </p>
        );
      })}
    </div>
  );
};

export default ResourcesLinks;
